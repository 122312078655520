import React, { useEffect, useState } from "react";
import "./style.css"; // Ensure correct path to your style.css
import { GiHamburgerMenu } from "react-icons/gi";
import DataTable from "../DataTable/DataTable";
import { MdOutlineLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleList } from "react-icons/ci";
import { useNavigate } from "react-router";
import {
  fetchDataById,
  logoutUser,
  onDeleteTableData,
  onFetchFolder,

} from "../../action/authAction";
import UploadCSV from "../UploadCSV/UploadCSV";
import { MdDelete } from "react-icons/md";
import DetailFolder from "../DetailFolder/DetailFolder";
import { fetchData } from "../../action/authAction";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfData from "../PdfData/PdfData";
import { FaDownload } from "react-icons/fa";
import CreateFolder from "../../CreateFolder/CreateFolder";
import FolderRow from "./FolderRow";
import Swal from "sweetalert2";
import Footer from "../Common/Footer/Footer";
import { Helmet } from "react-helmet";

const invoiceData = {
  logo: "your_base64_encoded_logo",
  name: "John Doe",
  email: "john.doe@example.com",
  date: "2024-07-23",
  productName: "Software License",
  price: 100,
};

const country = {
  country_name: "United Arab Emirates",
  code: "UAE",
};

const NavbarComp = () => {
  const [activeTab, setActiveTab] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMsbVisible, setIsMsbVisible] = useState(false);
  const [folder, setFolder] = useState([]);
  const [refresh, setRefresh] = useState(false)

  const [loader, setLoader] = useState(true);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const auth = useSelector((state) => state.auth);
  const [totalRows, setTotalRows] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  const toggleSidebar = () => {
    setIsMsbVisible(!isMsbVisible);
    document.body.classList.toggle("msb-x");
  };

  const handelFetchTable = () => {
    dispatch(
      fetchData(1, perPage, datatable, setDatatable, setTotalRows, setLoader)
    );
  };



  const handelFetchTableById = () => {
    // if (selectedFolderId === null) return;

    const data = {
      fId: selectedFolderId,
      pageno: 1,
      limit: perPage,
    };

    dispatch(fetchDataById(data, datatable, setDatatable, setTotalRows, setLoader));
  };
  const [datatable, setDatatable] = useState({
    columns: [
      {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
        grow: 0,
        wrap: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        grow: 0,
        wrap: true,
      },
      {
        name: "Customer Name",
        selector: (row) => row.name,
        grow: 1,
        wrap: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        grow: 1,
        wrap: true,
      },
      {
        name: "Location",
        selector: (row) => row.location,
        grow: 0,
        wrap: true,
      },
      {
        name: "Product Name",
        selector: (row) => row.productName,
        grow: 0,
        wrap: true,
      },
      {
        name: "Price",
        selector: (row) => row.price,
        grow: 0,
        wrap: true,
      },
      {
        name: "Transaction ID",
        selector: (row) => row.transaction,
        sortable: true,
        grow: 1,
        wrap: true,
      },
      {
        name: "Status",
        selector: (row) => row.sheetStatus,
        button: true,
        grow: 0,
        wrap: true,
      },
      {
        name: "Payment Mode",
        selector: (row) => row.paymentMode,
        button: true,
        grow: 0,
        wrap: true,
      },
      {
        name: "Action",
        cell: (row) => {
          const handleDownload = () => {
            const MyDocument = () => (
              <PdfData data={invoiceData} country={country} row={row} />
            );

            return (
              <PDFDownloadLink
                document={<MyDocument />}
                fileName="document.pdf"
                className="downloadLink"
              >
                {({ loader }) =>
                  loader ? (
                    "Loading document..."
                  ) : (
                    <FaDownload className="downloadIcon" />
                  )
                }
              </PDFDownloadLink>
            );
          };

          return (
            <>
              <MdDelete
                className="deleteIcon"
                onClick={() => handleDeleteBtn(row.id)}
              />
              {handleDownload()}
            </>
          );
        },
        button: true,
        grow: 0,
        wrap: true,
      },
    ],
    rows: [],
  });
  const handleDeleteBtn = async (row) => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Are you sure you want to delete this invoice!",
      showCancelButton: true,
      confirmButtonColor: "#d03e3e",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      dispatch(onDeleteTableData(row, handelFetchTable));
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser(navigate));
  };

  const fetchFolder = () => {
    dispatch(onFetchFolder(setFolder, setLoader));
  };

  useEffect(() => {
    if (datatable.length > 0) {
      const filtered = datatable.filter(
        (item) => +item.id === +selectedFolderId
      );
      setFilteredData(filtered);
    }
  }, [selectedFolderId, datatable]);



  const allData = () => {
    setSelectedFolderId(null);
    handelFetchTableById();
    handelFetchTable();
  };
  useEffect(() => {
    fetchFolder();
  }, []);
  return (
    <>
      <nav className="navbar header navbar-default fixed-top">
        <Helmet>
          <title>Reader-Invoice | Dashboard</title>
        </Helmet>
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle rounded-1 border-0"
              id="hamburger"
              onClick={toggleSidebar}
            >
              <span>
                <GiHamburgerMenu className="iconham" />
              </span>
            </button>
          </div>
          <div className="logout">
            <span className="logtxt">Welcome</span>
            <div>
              <button
                className="btnLog bg-dark text-light px-3 py-1"
                onClick={handleLogout}
              >
                <MdOutlineLogout />
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className={`msb ${isMsbVisible ? "msb-x" : ""
                } msb-hide mr-hide hide-scrollbar`}
              id="msb"
            >
              <div className="imagedata">
                <div className="user-profile w-100 py-3 rounded">
                  <img
                    src={"https://picsum.photos/200"}
                    alt="image"
                    className="image rounded-circle"
                    width={"80px"}
                  />
                  <div className="user-div">
                    <span className="user-email">{auth.user.email}</span>
                  </div>
                </div>
                <div className="uploadList border-top mt-3">
                  <ul className="p-0 m-0">
                    <li>
                      <UploadCSV
                        setFolder={setFolder}
                        folder={folder}
                        setDatatable={setDatatable}
                        selectedFolderId={selectedFolderId}
                        perPage={perPage}
                        setTotalRows={setTotalRows}
                        handelFetchTableById={handelFetchTableById}
                        handelFetchTable={handelFetchTable}
                        handleDeleteBtn={handleDeleteBtn}
                      />
                    </li>
                    <div className="d-flex align-items-center gap-1">
                      <button
                        className={`btnCreate rounded-1 text-light px-3 py-2 px-1 d-flex align-items-center ${!activeTab ? "side-active" : "side-inactive"}`}
                        onClick={() => { allData(); setActiveTab(false) }}
                      >
                        <CiCircleList className={`iconlist me-1`} />
                        All List Data
                      </button>
                      <CreateFolder
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        setFolder={setFolder}
                        setLoader={setLoader}
                      />
                    </div>
                    <hr />

                    {folder?.map((item, index) => {
                      return (
                        <FolderRow
                          item={item}
                          setSelectedFolderId={setSelectedFolderId}
                          handelFetchTable={handelFetchTable}
                          selectedFolderId={selectedFolderId}
                          fetchFolder={fetchFolder}
                          handelFetchTableById={handelFetchTableById}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main_table_wrapper">
        <div className="mcw">
          {selectedFolderId ? (
            <>
              <DetailFolder
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                totalRows={totalRows}
                perPage={perPage}
                setPerPage={setPerPage}
                datatable={datatable}
                setDatatable={setDatatable}
                setTotalRows={setTotalRows}
                selectedFolderId={selectedFolderId}
                loader={loader}
                setLoader={setLoader}
                setFolder={setFolder}
                folder={folder}
                invoiceData={invoiceData}
                country={country}
                handelFetchTable={handelFetchTable}
                handelFetchTableById={handelFetchTableById}
                setRefresh={setRefresh}

              />
            </>
          ) : (
            <>
              <DataTable
                perPage={perPage}
                setPerPage={setPerPage}
                selectedFolderId={selectedFolderId}
                setLoader={setLoader}
                loader={loader}
                invoiceData={invoiceData}
                country={country}
                setFolder={setFolder}
                folder={folder}
                datatable={datatable}
                setDatatable={setDatatable}
                setRefresh={setRefresh}
                handelFetchTable={handelFetchTable}
                setTotalRows={setTotalRows}
                totalRows={totalRows}
                handelFetchTableById={handelFetchTableById}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavbarComp;
