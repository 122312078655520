import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CiCircleList } from "react-icons/ci";
import { RxUpload } from "react-icons/rx";
import {
    fetchDataById,
    onFetchFolder,
    onMoveRowData,
    onUploadDocs,
    onUploadMedia,
} from "../../action/authAction";
import { useDispatch } from "react-redux";
import { themeColor } from "../../Global/Global";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";

let mediaType;

const MoveData = ({
    setFolder, folder, selectRow, setSelectedRows, folderEmpty, handelFetchTableById, toggleCleared, setToggleCleared
}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [percent, setPercent] = useState(0);
    const [moveData, setMoveData] = useState([]);
    const [optionId, setOptionId] = useState("select folder");
    const [fileName, setFileName] = useState("");
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const handleClose = () => {
        setShow(false);
        setOptionId("select folder");
        setFileName("");
    };
    const fetchFolder = () => {
        dispatch(onFetchFolder(setFolder, setLoader));
    };
    const handleMove = () => {
        let data = {
            id: selectRow,
            fId: optionId

        }
        setLoader(true)
        dispatch(onMoveRowData(data, handelFetchTableById, handleClose, setSelectedRows, setLoader))
        setSelectedRows([]);
        setToggleCleared(!toggleCleared);

    }
    useEffect(() => {
        fetchFolder();
    }, []);
    const handleChange = (id) => {
        setOptionId(id);
    };
    return (
        <>
            <button
                onClick={handleShow}
                className="upload rounded-2 text-light py-2 px-4"
                disabled={folderEmpty || selectRow.length === 0}

            >
                Move
            </button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <p className="m-0 modal-heading">Upload CSV</p>
                </Modal.Header>
                <Modal.Body
                    style={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                    <div>
                        <p className="m-0 choose-file">Choose File</p>
                        <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e.target.value)}
                        >
                            <option selected>Choose Folder</option>
                            {folder?.map((item, index) => {
                                return (
                                    <>
                                        <option value={item.id} key={index}>
                                            {item.name}
                                        </option>
                                    </>
                                );
                            })}
                        </select>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="handle">
                        <Button onClick={handleClose} className="btnhandle closeBtn">
                            Close
                        </Button>
                        <Button
                            onClick={handleMove}
                        >
                            Move {loader ? <FaSpinner className="spin" /> : " "}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MoveData;
