import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    fetchDataById,
    onDeleteSelectData,
    onDeleteTableData,
} from "../../action/authAction";
import ReactTable from "react-data-table-component";
import PdfData from "../PdfData/PdfData";
import { FaDownload } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import Footer from "../Common/Footer/Footer";
import ReactHtmlParser from "react-html-parser";
import MoveData from "../MoveData/MoveData";

const DetailFolder = ({
    selectedFolderId,
    loader,
    totalRows,
    perPage,
    setTotalRows,
    setPerPage,
    setLoader,
    invoiceData,
    country,
    handelFetchTableById,
    datatable,
    setDatatable,
    setRefresh,
    setFolder, folder,
    handelFetchTable
}) => {
    const dispatch = useDispatch();
    const [q, setQ] = useState("");
    const [toggleCleared, setToggleCleared] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [selectRow, setSelecteRow] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const dynamicTableRow = (tableData) => {
        const rows = search(tableData.rows)?.map((row) => ({
            ...row,
            name: ReactHtmlParser(row.name),
            email: ReactHtmlParser(row.email),
        }));
        return rows;
    };
    const search = (users) => {
        return users.filter((user) =>
            user.name || user.email
                ? user?.name?.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
                user?.email?.toLowerCase().indexOf(q.toLowerCase()) > -1
                : ""
        );
    };

    const getQ = (e) => {
        setQ(e.target.value);
    };
    const folderEmpty = !datatable || datatable.length === 0;

    useEffect(() => {
        const fetchData = async (pageno) => {
            try {
                let data = {
                    fId: selectedFolderId,
                    pageno: pageno,
                    limit: perPage,
                };
                await dispatch(
                    fetchDataById(data, datatable, setDatatable, setTotalRows, setLoader)
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(1, perPage);
    }, [selectedFolderId, perPage]);

    useEffect(() => {
        if (datatable.length > 0) {
            const filtered = datatable.filter(
                (item) => +item.folderId === +selectedFolderId
            );
            setFilteredData(filtered);
        }
    }, [selectedFolderId, datatable]);


    // const handleChange = (e) => {
    //     const selectPerRow = e.selectedRows.map((item) => {
    //         return item.id;
    //     });
    //     const selectPerPdf = e.selectPerRow;
    //     setSelecteRow(selectPerRow);
    //     setSelectedRows(selectPerPdf);
    // };

    const handleChange = (e) => {
        const selectPerRow = e.selectedRows.map((item) => item.id);
        setSelecteRow(selectPerRow);
        setSelectedRows(e.selectedRows);
    };

    const deleteAll = async () => {
        const result = await Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            showCancelButton: true,
            confirmButtonColor: "#d03e3e",
            cancelButtonColor: "#6c757d",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        });

        if (result.isConfirmed) {

            try {
                await dispatch(
                    onDeleteSelectData(
                        selectRow,
                        handelFetchTableById,
                        toggleCleared,
                        setToggleCleared,
                        setRefresh
                    )
                );
                setSelecteRow([]);
                setSelectedRows([]);
            } catch (error) {
                console.error('Error deleting items:', error);
            }
        }
    };

    const downloadAll = async () => {
        const pdfs = await Promise.all(
            selectRow.map(async (id) => {
                const selectedRow = datatable.rows.find((row) => +row.id === +id);
                const MyDocument = () => (
                    <PdfData data={invoiceData} country={country} row={selectedRow} />
                );
                const blob = await pdf(<MyDocument />).toBlob();
                return { blob, id };
            })
        );

        for (const { blob, id } of pdfs) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `document-${id}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        setSelecteRow([]);
        setSelectedRows([]);
        setToggleCleared(!toggleCleared);
    };

    const handlePerRowsChange = (newPerPage, pageno) => {
        let data = {
            fId: selectedFolderId,
            pageno: pageno,
            limit: newPerPage,
        };


        dispatch(fetchDataById(data, datatable, setDatatable, setTotalRows, setLoader));
        setPerPage(newPerPage);
    };

    const handlePageChange = (pageno) => {
        let data = {
            fId: selectedFolderId,
            pageno: pageno,
            limit: perPage,
        };

        dispatch(fetchDataById(data, datatable, setDatatable, setTotalRows, setLoader));
    };

    return (
        <>
            <div className="container-fluid mt-3 h-100">
                <div className="row h-100">
                    <div className="col-12 p-0">
                        <div className="data_table_warp h-100 px-4">
                            <div className="d-flex flex-column px-0 py-2 flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-3">
                                <div className="tableBtn">
                                    <button
                                        type="button"
                                        className="download"
                                        onClick={downloadAll}
                                        disabled={folderEmpty || selectRow.length === 0}
                                    >
                                        Download All
                                    </button>
                                    <button
                                        type="button"
                                        className="delete"
                                        onClick={deleteAll}
                                        disabled={folderEmpty || selectRow.length === 0}
                                    >
                                        Delete All
                                    </button>

                                    <MoveData setFolder={setFolder} folderEmpty={folderEmpty} folder={folder} selectRow={selectRow} setSelectedRows={setSelectedRows} selectedFolderId={selectedFolderId} handelFetchTableById={handelFetchTableById} toggleCleared={toggleCleared} setToggleCleared={setToggleCleared} />

                                </div>

                                <div className="dropdown">
                                    <div className="search  d-flex align-items-center">
                                        <span>
                                            <FiSearch className="fs-5" />
                                        </span>
                                        <input
                                            type="text"
                                            value={q}
                                            onChange={getQ}
                                            placeholder="Search here..."
                                        />
                                    </div>
                                </div>
                            </div>

                            <ReactTable
                                columns={datatable.columns}
                                data={dynamicTableRow(datatable)}
                                selectableRows
                                pagination
                                paginationServer
                                responsive={true}
                                progressPending={loader}
                                highlightOnHover={true}
                                onChangePage={handlePageChange}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onSelectedRowsChange={handleChange}
                                clearSelectedRows={toggleCleared}
                                selectedRows={selectedRows}
                                persistTableHead={true}
                                noDataComponent={
                                    <h5 className="text-center pt-4 pb-3 "> No Record Found</h5>
                                }
                            />
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailFolder;
