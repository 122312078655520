import React, { useEffect, useState } from "react";
import "./style.css";
import ReactTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { fetchData, onDeleteSelectData } from "../../action/authAction";
import ReactHtmlParser from "react-html-parser";
import PdfData from "../PdfData/PdfData";
import { pdf } from "@react-pdf/renderer";
import { FiSearch } from "react-icons/fi";
import Swal from "sweetalert2";
import Footer from "../Common/Footer/Footer";
import MoveData from "../MoveData/MoveData";

const DataTable = ({
  loader,
  setLoader,
  totalRows,
  setTotalRows,
  perPage,
  invoiceData,
  country,
  setPerPage,
  handelFetchTable,
  selectedFolderId,
  datatable,
  setDatatable,
  setRefresh,
  setFolder,
  folder,
  handelFetchTableById

}) => {
  const dispatch = useDispatch();
  const [q, setQ] = useState("");
  const [selectRow, setSelecteRow] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const search = (users) => {
    return users?.filter((user) =>
      user.name || user.email
        ? user?.name?.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        user?.email?.toLowerCase().indexOf(q.toLowerCase()) > -1
        : ""
    );
  };

  const getQ = (e) => {
    setQ(e.target.value);
  };

  const dynamicTableRow = (tableData) => {
    const rows = search(tableData.rows)?.map((row) => ({
      ...row,
      name: ReactHtmlParser(row.name),
      email: ReactHtmlParser(row.email),
    }));
    return rows;
  };

  const handleChange = (e) => {
    const selectPerRow = e.selectedRows.map((item) => item.id);
    setSelecteRow(selectPerRow);
    setSelectedRows(e.selectedRows);
  };

  const deleteAll = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      showCancelButton: true,
      confirmButtonColor: "#d03e3e",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(
          onDeleteSelectData(
            selectRow,
            handelFetchTable,
            toggleCleared,
            setToggleCleared,
            setRefresh
          )
        );
        setSelecteRow([]);
        setSelectedRows([]);
      } catch (error) {
        console.error('Error deleting items:', error);
      }
    }
  };

  const downloadAll = async () => {
    const pdfs = await Promise.all(
      selectRow.map(async (id) => {
        const selectedRow = datatable.rows.find((row) => +row.id === +id);
        const MyDocument = () => (
          <PdfData data={invoiceData} country={country} row={selectedRow} />
        );
        const blob = await pdf(<MyDocument />).toBlob();
        return { blob, id };
      })
    );

    for (const { blob, id } of pdfs) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `document-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    setSelecteRow([]);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const handleShow = () => {

  }
  const handlePerRowsChange = (newPerPage, pageno) => {
    dispatch(
      fetchData(
        pageno,
        newPerPage,
        datatable,
        setDatatable,
        setTotalRows,
        setLoader
      )
    );
    setPerPage(newPerPage);
  };

  const handlePageChange = (pageno) => {
    dispatch(
      fetchData(
        pageno,
        perPage,
        datatable,
        setDatatable,
        setTotalRows,
        setLoader
      )
    );
  };

  useEffect(() => {
    handelFetchTable();
  }, []);

  return (
    <>
      <div className="container-fluid mt-3 h-100">
        <div className="row h-100">
          <div className="col-12 p-0">
            <div className="data_table_warp h-100 px-4">
              <div className="d-flex flex-column px-0 py-2 flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-3">
                <div className="tableBtn">
                  <button
                    type="button"
                    className="download"
                    onClick={downloadAll}
                    disabled={selectRow.length === 0}
                  >
                    Download All
                  </button>
                  <button
                    type="button"
                    className="delete"
                    onClick={deleteAll}
                    disabled={selectRow.length === 0}
                  >
                    Delete All
                  </button>

                  <MoveData setFolder={setFolder} folder={folder} selectRow={selectRow} setSelectedRows={setSelectedRows} selectedFolderId={selectedFolderId} handelFetchTableById={handelFetchTable} toggleCleared={toggleCleared} setToggleCleared={setToggleCleared} />

                </div>

                <div className="dropdown">
                  <div className="search d-flex align-items-center">
                    <span>
                      <FiSearch className="fs-5" />
                    </span>
                    <input
                      type="text"
                      value={q}
                      onChange={getQ}
                      placeholder="Search here..."
                    />
                  </div>
                </div>
              </div>
              <div className="rounded-2 p-0">
                <ReactTable
                  columns={datatable.columns}
                  data={dynamicTableRow(datatable)}
                  selectableRows
                  pagination
                  paginationServer
                  responsive
                  progressPending={loader}
                  highlightOnHover
                  onChangePage={handlePageChange}
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onSelectedRowsChange={handleChange}
                  clearSelectedRows={toggleCleared}
                  selectedRows={selectedRows}
                  persistTableHead
                  noDataComponent={
                    <h5 className="text-center pt-4 pb-3">No Record Found</h5>
                  }
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
